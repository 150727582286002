
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Article } from './types'

@Component
export default class ArticlePageCitations extends Vue {
  @Prop({ required: true }) article!: Article

  //
  // COMPUTED PROPERTIES
  //

  get fromReviewsQuery() {
    const paddedId = this.article.paperId.toString().padStart(7, '0')
    return `r:(MR${paddedId} OR "refcno ${this.article.paperId}")`
  }
}
