
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Article } from './types'

@Component
export default class ArticlePageRelated extends Vue {
  @Prop({ required: true }) article!: Article

  //
  // METHODS
  //
  linkToAuthorPage(authorId: number) {
    return authorId && authorId > 0 ? 'router-link' : 'span'
  }
}
