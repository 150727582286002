
import { Component, Prop, Vue } from 'vue-property-decorator'
import { addLastDot, parseReview } from '@/utils/utils'
import { Article } from './types'

@Component
export default class ArticlePageBibInfoCommon extends Vue {
  @Prop({ required: true }) article!: Article
  @Prop({ required: true }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet

  //
  // COMPUTED PROPERTIES
  //

  get articleTitle(): string {
    if (!this.article.titles?.title) return ''

    const title = parseReview(this.article.titles.title)
    return addLastDot(title)
  }

  get translatedTitle(): string | undefined {
    if (!this.article.titles?.translatedTitle) return undefined

    const title = parseReview(this.article.titles.translatedTitle)

    if (title.charAt(0) === '[') {
      return title
    }

    return `[${title}]`
  }

  get romanText(): string {
    if (this.article.romanText) {
      return this.article.romanText
        // .filter(t => t.toLowerCase().indexOf('reprint of') === -1)
        .map(t => this.clearPunctuation(t)).join('. ')
    }

    return ''
  }

  get languages(): string | undefined {
    if (this.article.language) {
      const langs = this.article.language
        .filter(lang => lang.summaryLanguage.trim() === 'N')
        .filter(lang => lang.abbreviation !== 'EN' && lang.abbreviation !== 'EE') // Ommit "English" from displayed languages, but keep it for Summaries
        .map(lang => lang.name)
        .reduce<string[]>((filter, current) => { // Deduplicate languages that had different abbreviations (EE & EN for English, FF & FR for French)
          const dup = filter.find(item => item === current)
          if (dup) {
            return filter
          } else {
            return filter.concat(current)
          }
        }, [])
        .join('. ')

      // MR0444661
      const summary = this.article.language
        .filter(lang => lang.summaryLanguage.trim() === 'Y')
        // .map(lang => `${lang.name} summary`)
        .map(lang => lang.name)
        .reduce<string[]>((filter, current) => { // Deduplicate languages that had different abbreviations (EE & EN for English, FF & FR for French)
          const dup = filter.find(item => item === current)
          if (dup) {
            return filter
          } else {
            return filter.concat(current)
          }
        }, [])
        // .join('. ')
        .join(', ')

      // summary = summary !== '' ? `. ${summary}` : ''
      if (langs.length === 0) {
        return summary.trim().length > 0 ? `(${summary} summary)` : ''
      } else {
        return summary.trim().length > 0 ? `(${langs}. ${summary} summary)` : `(${langs})`
      }
    }

    return undefined
  }

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    window.goToArticle = this.goToArticle.bind(this)
  }

  beforeDestroy() {
    window.goToArticle = window._goToArticle
  }

  //
  // METHODS
  //

  clearPunctuation(text: string): string {
    // Clear trailing commas and periods, like in MR0507436
    text = this.dashReplacement(text)
    return text.replace(/\.$|,$/, '')
  }

  dashReplacement(text: string): string {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }

  goToArticle(mr: number) {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    this.$router
      .push({
        name: this.unsubbed ? 'RelayStation' : 'ArticlePage',
        query: { mr: mr.toString() },
      })
      .catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
  }
}
