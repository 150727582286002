
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PageHeader } from '@/components/page-header'
import { Article } from './types'
import { addLeadingZeros } from '@/utils/utils'

@Component({
  components: {
    PageHeader,
  },
})
export default class ArticlePageHeader extends Vue {
  @Prop({ required: true }) article!: Article
  @Prop({ default: false, type: Boolean }) noLogo!: boolean

  get formattedMRNumber() {
    return this.article.paperId ? addLeadingZeros(this.article.paperId.toString()) : ''
  }
}
