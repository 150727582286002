import { Vue, Component, Prop } from 'vue-property-decorator'
import { Article, Paging, Issue } from './types'

@Component
export class ArticlePageBibInfoMixin extends Vue {
  @Prop({ required: true }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet
  @Prop({ required: true }) article!: Article

  //
  // COMPUTED PROPERTIES
  //

  get unsubbedLinkDisable(): string {
    // use :is="unsubbedLinkDisable" to make any RouterLink stop working if public
    return this.unsubbed ? 'span' : 'router-link'
  }

  get paging(): Paging {
    return this.article.pagings?.paging
  }

  get translatedPaging(): Paging {
    return this.article.pagings?.translatedPaging
  }

  //
  // METHODS
  //
  dashReplacement(text: string): string {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }

  clearPunctuation(text: string): string {
    // Clear trailing commas and periods, like in MR0507436
    text = this.dashReplacement(text)
    return text.replace(/\.$|,$/, '')
  }

  formatYear(year1: string, year2: string|undefined = undefined): string {
    // Sometimes the database saves it as '(yyyy),' which messes with the formatting
    let formatted = year1.replaceAll('(', '').replaceAll(')', '')
    formatted = this.clearPunctuation(formatted) // remove any trailing commas

    if (year2) {
      formatted += '/'
      formatted += this.clearPunctuation(year2.replaceAll('(', '').replaceAll(')', ''))
    }

    return formatted
  }

  formatIssue(issue: string|number): string {
    // Some issue numbers include 'no. X,' which messes with the formatting
    let formatted = `${issue}`.replaceAll('no.', '').trim()
    formatted = this.clearPunctuation(formatted)

    return formatted
  }

  formatVolume(issue: Issue): string {
    if (!issue.volume) {
      return ''
    }
    let result = `${issue.volume}`
    if (issue.volume2) {
      if (issue.volSlash && issue.volSlash.toLowerCase() === 'p') {
        result = `${result}(${issue.volume2})`
      } else {
        result = `${result}/${issue.volume2}`
      }
    }
    if (issue.volume3) {
      if (issue.volSlash && issue.volSlash.toLowerCase() === 'p') {
        result = `${result}(${issue.volume3})`
      } else {
        result = `${result}/${issue.volume3}`
      }
    }
    return result
  }
}
