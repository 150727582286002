
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Article } from './types'

@Component
export default class ArticlePageContainer extends Vue {
  @Prop() articleId!: number
  @Prop() article!: Article
  @Prop() articles!: Article[]
  @Prop({ default: 'articleId' }) paramName!: string

  @Prop({ default: true }) checkUrl!: boolean
  @Prop({ default: false }) loading!: boolean
}
