
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Article } from './types'

@Component
export default class ArticlePageClassifications extends Vue {
  @Prop({ required: true }) article!: Article
  @Prop({ required: true }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet

  //
  // COMPUTED PROPERTIES
  //

  get sortedSecondary() {
    if (this.article && this.article.secondaryClass) {
      return this.article.secondaryClass.sort((x, y) => x.code.localeCompare(y.code))
    }
    return []
  }

  get primaryClassURL() {
    return {
      name: this.unsubbed ? 'FreeToolsSearchMsc' : 'SearchMsc',
      query: {
        text: this.article.primaryClass.code,
      },
    }
  }

  //
  // METHODS
  //
  secondaryClassURL(sClass) {
    return {
      name: this.unsubbed ? 'FreeToolsSearchMsc' : 'SearchMsc',
      query: {
        text: sClass.code,
      },
    }
  }
}
