
import { ArticlesAPI, PublicationsAPI } from '@/api'
import { PublicationFormat } from '@/global-types'
import { scrollTo, updateMathJax } from '@/utils/utils'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import ArticlePageBibInfo from './ArticlePageBibInfo.vue'
import ArticlePageCitations from './ArticlePageCitations.vue'
import ArticlePageClassifications from './ArticlePageClassifications.vue'
import ArticlePageContainer from './ArticlePageContainer.vue'
import ArticlePageDefault from './ArticlePageDefault.vue'
import ArticlePageHeader from './ArticlePageHeader.vue'
import ArticlePageReferences from './ArticlePageReferences.vue'
import ArticlePageReview from './ArticlePageReview.vue'
import ArticlePageSubheader from './ArticlePageSubheader.vue'
import InternalLinks from '@/components/InternalLinks.vue'
import ArticlePageRelated from './ArticlePageRelated.vue'
import { Article, CitationFormat } from './types'
import { trackArticleCounter5 } from '@/counter5-tracker'

@Component({
  components: {
    ArticlePageDefault,
    ArticlePageHeader,
    ArticlePageSubheader,
    ArticlePageBibInfo,
    ArticlePageClassifications,
    ArticlePageCitations,
    ArticlePageReferences,
    ArticlePageReview,
    ArticlePageContainer,
    InternalLinks,
    ArticlePageRelated,
  },
})
export default class ArticlePageSharedReview extends Vue {
  @Ref() container!: HTMLElement

  @Prop() article!: Article;
  @Prop({ required: false, default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet

  //
  // REACTIVE PROPERTIES
  //
  citationFormats: CitationFormat[] = []
  articles: Article[] = []
  loading = false
  reviewArticle: Article | null = null

  scrollTo = scrollTo

  //
  // WATCHERS
  //
  @Watch('article', { immediate: true })
  onChangeArticle() {
    this.processArticle()
  }

  //
  // COMPUTED PROPERTIES
  //
  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  //
  // LIFECYCLE HOOKS
  //

  mounted() {
    updateMathJax()
  }

  updated() {
    const relatedMRNumber = this.article.relation.relatedPapers[this.article.relation.relatedPapers.length - 1]
    this.reviewArticle = this.articles.filter(paper => paper.paperId === relatedMRNumber)[0]
    if (this.container && this.article) {
      this.scrollTo(`#shared-rev-MR${this.article.paperId}`)
    }

    updateMathJax()
  }

  //
  // METHODS
  //

  lookForCitationFormat(articleId: number): CitationFormat {
    const noFormats = {
      ams: '',
      bib: '',
      tex: '',
      end: '',
    }

    if (!this.citationFormats) return noFormats

    const format = this.citationFormats.find(cf => cf.paperId === articleId)

    if (format) return format

    return noFormats
  }

  async processArticle() {
    if (this.article) {
      const otherPaperIds = this.article.relation.relatedPapers.filter(id => id !== this.article.paperId)

      this.loading = true
      const otherArticles = await ArticlesAPI.getArticles(otherPaperIds)
      this.loading = false

      this.articles = [this.article, ...otherArticles].sort((a, b) => {
        const aId = a.details.classicMrnum || a.paperId
        const bId = b.details.classicMrnum || b.paperId

        if (aId < bId) return -1
        if (aId > bId) return 1

        return 0
      })

      this.citationFormats = await this.getCitationFormats(this.articles.map(article => article.paperId))

      // const hasReview = this.articles.reduce((prev, curr) => {
      //   if (curr.review && curr.review.trim().length > 0) return prev || true

      //   return prev || false
      // }, false)

      // if (hasReview) {
      if (!this.unsubbed) {
        this.articles.forEach(article => trackArticleCounter5(article))
      }
      // }
    }
  }

  async getCitationFormats(articleIds: number[]) {
    const results = await PublicationsAPI.getPublicationFormat(
      [PublicationFormat.AMS, PublicationFormat.BIB, PublicationFormat.TEX, PublicationFormat.END],
      articleIds
    )

    return results
  }

  getReview(item: Article) {
    if (item.review) {
      return item.review
    } else if (item.prePubl && item.prePubl.review) {
      return item.prePubl.review
    }

    return null
  }

  // longestReviewIndex() {
  //   const lengths = this.articles.map(x => x.review.length)
  //   return lengths.indexOf(Math.max(...lengths))
  // }
}
