
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ArticlePageBibInfoMixin } from './mixins'
import { addLastDot } from '@/utils/utils'
import { Issue } from './types'
import ArticlePageBibInfoCommon from './ArticlePageBibInfoCommon.vue'

@Component({
  components: {
    ArticlePageBibInfoCommon,
  },
})
export default class ArticlePageBibInfoJournalInfo extends mixins(ArticlePageBibInfoMixin) {
  //
  // COMPUTED PROPERTIES
  //
  get issue() {
    return this.article.issue?.issue
  }

  get translatedIssue() {
    return this.article.issue?.translatedIssue
  }

  get journalLink() {
    if (!this.issue?.journal) return {}

    const journalId = this.issue.journal.jourId

    if (journalId !== null) {
      return { name: 'SerialProfile', query: { journalId } }
    }
    // It's an old entry that doesn't have a journalId. Have to search for it instead.
    return {
      name: 'JournalSearch',
      query: {
        query: `"${this.issue.journal.shortTitle}"`,
      },
    }
  }

  get journalTitle() {
    if (!this.issue?.journal?.title) return ''

    return addLastDot(this.issue.journal.title)
  }

  get journalAbbr() {
    if (!this.issue?.journal?.shortTitle) return ''

    return this.issue.journal.shortTitle
  }

  //
  // METHODS
  //

  publicationQueries(pissue: Issue) {
    if (!pissue) return { year: null, vol: null, iss: null }

    const publication = pissue.journal.jourId === null
      ? `j:"${this.journalAbbr}"`
      : `ji:${pissue.journal.jourId}`

    const issue = `iss:${pissue.issNum}`
    const volume = pissue.volume ? `v:${pissue.volume}` : ''
    const year = pissue.pubYearInt ? `y:${pissue.pubYearInt}` : ''

    return {
      year: `${publication} ${year}`.trim(),
      vol: `${publication} ${volume}`.trim(),
      iss: `${publication} ${volume} ${issue}`.trim(),
      exact: `${publication} ${year} ${volume} ${issue}`.trim(),
    }
  }
}
