
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  Article, ArticlePageHeader, ArticlePageSubheader,
  ArticlePageBibInfo, ArticlePageClassifications, ArticlePageCitations,
  ArticlePageReferences, ArticlePageRelated, ArticlePageReview,
} from '@/components/article-page'
import { PublicationsAPI } from '@/api'
import { PublicationFormat } from '@/global-types'
import InternalLinks from '@/components/InternalLinks.vue'
import { updateMathJax } from '@/utils/utils'
import { trackArticleCounter5 } from '@/counter5-tracker'

@Component({
  components: {
    ArticlePageHeader,
    ArticlePageSubheader,
    ArticlePageBibInfo,
    ArticlePageClassifications,
    ArticlePageCitations,
    ArticlePageReferences,
    ArticlePageRelated,
    ArticlePageReview,
    InternalLinks,
  },
})
export default class ArticlePageDefault extends Vue {
  @Prop({ required: true }) article!: Article
  @Prop({ required: false, default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet

  citationFormats = {
    ams: '',
    bib: '',
    tex: '',
  }

  async mounted() {
    updateMathJax()

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  //
  // WATCHERS
  //

  @Watch('article', { immediate: true })
  onArticleChange() {
    if (this.article) {
      this.getCitationFormats()

      if (!this.unsubbed) {
        trackArticleCounter5(this.article)
      }
    }
  }

  //
  // COMPUTED PROPERTIES
  //
  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  //
  // LIFE CYCLE HOOKS
  //

  updated() {
    updateMathJax()
  }

  async getCitationFormats() {
    const results = await PublicationsAPI.getPublicationFormat(
      [PublicationFormat.AMS, PublicationFormat.BIB, PublicationFormat.TEX, PublicationFormat.END],
      [this.article.paperId]
    )
    this.citationFormats = results[0]
  }
}
