
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Article, SimpleAuthor } from './types'
import ArticlePageBibInfoJournalInfo from './ArticlePageBibInfoJournalInfo.vue'
import ArticlePageBibInfoBookInfo from './ArticlePageBibInfoBookInfo.vue'

@Component({
  components: {
    ArticlePageBibInfoJournalInfo,
    ArticlePageBibInfoBookInfo,
  },
})
export default class ArticlePageBibInfo extends Vue {
  @Prop({ required: true }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet
  @Prop({ required: true }) article!: Article

  showInstCodeDetails = false
  instCodeAuthor:SimpleAuthor = {
    authId: -1,
    name: '',
    institutionCode: '',
    institutionName: '',
    institutionAddress: '',
  }

  generateAuthorLink(authorId: number) {
    if (this.unsubbed) {
      return { name: 'MRAuthorID', params: { authorID: authorId } }
    }

    return { name: 'AuthorPage', query: { authorId: authorId } }
  }

  displayInstCode(author) {
    return author.authId !== -1 &&
      author.institutionCode &&
      author.institutionCode.length > 0 &&
      author.institutionCode !== 'NA' &&
      author.institutionCode !== 'ZZZZZZZZZZZZ' &&
      author.institutionCode !== 'NC'
  }

  showInstCodeForAuthor(author: SimpleAuthor) {
    this.instCodeAuthor = author
    this.showInstCodeDetails = true
  }
}
