
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Article } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class ArticlePageReferences extends Vue {
  @Prop({ required: true }) article!: Article
  @Prop({ default: false }) hiddenContent!: boolean

  //
  // REACTIVE DATA
  //
  // hiddenContent = false

  //
  // METHODS
  //
  formattedMRNumber(number) {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - number.toString().length
    return '0'.repeat(leadingZeros) + number
  }

  get citationMRNumbers() {
    return this.article.citations.filter(x => x.pub !== null).map(x => x.pub[0].paperId)
  }

  get citationSearchQuery() {
    // This method works, and might be nice to have as a backup later.
    // let fullQuery = ''
    // let counted = 0
    // const size = 10
    // while (counted < this.citationMRNumbers.length) {
    //   const part = 'mr:(' + this.citationMRNumbers.slice(counted, counted + size).join(' OR ') + ')'
    //   if (fullQuery.length > 0) {
    //     fullQuery += ' OR '
    //   }
    //   fullQuery += part
    //   counted += size
    // }
    // return fullQuery
    return '@|mr:' + this.citationMRNumbers.join(',') // Not sure how to explain this @| syntax to users
  }

  displayContent() {
    this.hiddenContent = !this.hiddenContent
  }
}
