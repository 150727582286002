
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ArticlePageBibInfoMixin } from './mixins'
import ArticlePageBibInfoCommon from './ArticlePageBibInfoCommon.vue'
import { Series } from './types'
import { addLastDot } from '@/utils/utils'

@Component({
  components: {
    ArticlePageBibInfoCommon,
  },
})
export default class ArticlePageBibInfoBookInfo extends mixins(ArticlePageBibInfoMixin) {
  //
  // COMPUTED PROPERTIES
  //

  get bcPaging() {
    // If the paging should display after the BCZ info, or after the publisher
    return this.article.details &&
      this.article.details.entryType === 'BC' &&
      this.article.book &&
      this.article.book.collectionTitle
  }

  get displayPaging() {
    const stopIfEndsWith = /[,.]/
    const text = this.dashReplacement(this.paging.text)

    return addLastDot(text, stopIfEndsWith)
  }

  //
  // METHODS
  //

  seriesLink(series: Series) {
    const seriesId = series.serId
    return { name: 'SerialProfile', query: { seriesId } }
  }

  fallbackSeriesLink(shortTitle: string) {
    return {
      name: 'PublicationsSearch',
      query: {
        query: `se: ${shortTitle.trim()}`,
      },
    }
  }

  seriesTranslatedTitle(series: Series) {
    if (series.transTitle.charAt(0) === '[') {
      return series.transTitle.trim()
    }

    return `[${series.transTitle.trim()}]`
  }

  formattedMRNumber(number) {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - number.toString().length
    return '0'.repeat(leadingZeros) + number
  }
}

